<template>
  <TTView>
    <VRow>
      <VCol>
        <TopicForm
          :entity="topic"
          :loading="loading"
          @update:name="topic.name = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>
<script>
import TopicForm from '../../../components/multi-level-test/topics/TopicForm.vue';

export default {
  name: 'TestsTopicsCreate',
  components: {
    TopicForm,
  },
  inject: ['Names'],
  data() {
    return {
      loading: false,
      topic: {},
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
  },
  methods: {
    handleCancel() {
      this.$router.push({ name: this.Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_INDEX, params: { testId: this.testId } });
    },
    async handleSubmit() {
      try {
        this.loading = true;

        const { testId, topic } = this;
        const data = { topic: { ...topic, testId } };
        await this.$di.api.MultiLevelTest.TopicsCreate({ data });
        this.$di.notify.snackSuccess('Топик создан');

        this.cleanTopic();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    cleanTopic() {
      this.topic = {};
    },
  },
};
</script>
