<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      <TTBtn
        color="tt-light-mono-100"
        icon
        :to="{
          name : Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_INDEX,
          params : { testId : $route.params.testId }
        }"
      >
        <VIcon>
          $prev
        </VIcon>
      </TTBtn>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Название"
                persistent-hint
                hint="Название"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <TTBtn
        text
        @click="handleCancel"
      >
        Отмена
      </TTBtn>

      <TTBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </TTBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'TopicForm',
  inject: ['Names'],
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    testId() {
      return this.$route.params.testId;
    },
    normalizedTitle() {
      let title = 'Создание топика';

      if (this.entity.id) {
        title = 'Редактирование топика';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
